import { ref, reactive, computed, watch, onMounted, toRaw, h } from "vue";
import { useStore } from "vuex";
import {
  fetchControllingcheckedListFl,
  getProgramFlList,
  getProgramDealerStatuslist,
} from "@/API/checking/spotChecking";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import useOverflowText from "@/utils/payment/useOverflowText";
import {
  spotCheckOptions,
  sampleCheckOptions,
  controllingCheckOptions,
  unChecked,
} from "@/views/Checking/spotChecking/spotCheckingControlling/spotCheckConfirm/components/vinViewDictionary";
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
type Key = ColumnProps["key"];
const useDealerVinView = () => {
  const { state } = useStore();
  //#region 字典项
  const statusRes = ref(state.spotChecking.spotCheckStatus);
  const statusList = computed(() => {
    const arr = [];
    for (const key in statusRes.value) {
      arr.push({
        name: statusRes.value[key],
        value: key,
      });
    }
    return arr;
  });
  //#endregion
  const column = ref([
    { title: "VIN", slots: { customRender: "vin" } },
    {
      title: "Model Name",
      dataIndex: "modelName",
      customRender: useOverflowText(),
    },
    {
      title: "SI Offer",
      dataIndex: "siOffer",
    },
    {
      title: "Spot Check Result",
      dataIndex: "spotCheckResult",
      customRender: ({ text }: { text: number | string }) => {
        return h("span", spotCheckOptions[text]);
      },
    },
    { title: "Spot Sampled", slots: { customRender: "spotSampled" } },
    
    {
      title: "Sample Check Result",
      dataIndex: "sampleCheckResult",
      customRender: ({ text }: { text: number | string }) => {
        return h("span", sampleCheckOptions[text]);
      },
    },
    { title: "Sample Amount",dataIndex: "sampleAmount", align: 'right', customRender: useThousandsAndTwoDecimal() },
    { title: "Con. Reject History", slots: { customRender: "rejectHistory" } },
    { title: "Controlling Sampled",  slots: { customRender: "controllingSampled" } },
    {
      title: "Controlling Check Result",
      dataIndex: "controllingCheckResult",
      customRender: ({ text }: { text: number | string }) => {
        return h("span", controllingCheckOptions[text]);
      },
    },
    { title: "Final Amount", dataIndex: "finalAmount", customRender: useThousandsAndTwoDecimal(), align: 'right' },
    { title: "Check Status", dataIndex: "checkStatus" },
    { title: "Operation", slots: { customRender: "operation" }, width: 150 },
  ]);
  const query = reactive({
    checkStatus: "-1",
    controllingSampled: "-1",
    dealerCode: "",
    dealerName: "",
    model: "",
    payoutCode: "",
    payoutCodeId: "",
    programCode: "",
    programCodeId: "",
    packageCode: "",
    packageCodeId: "",
    rejectHistory: false,
    siOffer: "",
    spotCheckSampled: "1",
    typeClass: "",
    vin: "",
  });
  // 获取传值
  const programState = state.spotChecking.spotCheckConfirmProgramFl;
  const payoutState = state.spotChecking.spotCheckConfirmPackageFl;
  query.programCode = programState.programCode;
  // query.programCodeId = programState.programId;
  query.payoutCode = payoutState.payoutCode;
  query.payoutCodeId = payoutState.id;
  query.siOffer = programState.siOffer;
  //#region [program 列表相关]
  //#endregion
  // program列表
  // 当前program
  const curProgram = ref(programState);
  const programRes = ref();
  const unCheckedProgramList = computed(() => {
    if (!programRes.value) {
      return [];
    }
    return programRes.value?.list?.filter((item: any) => {
      return unChecked.indexOf(item.status) !== -1;
    });
  });
  // 当前program索引
  const programIndex = ref();
  const isInProgram = ref<boolean>(false);
  watch(unCheckedProgramList, (arr) => {
    arr.forEach((item: any, index: number) => {
      if (item.programId === programState.programId) {
        programIndex.value = index;
        isInProgram.value = true;
      }
    });
  });
  // 获取program unChecked列表
  const getProgramList = () => {
    getProgramFlList(payoutState.id).then((res: any) => {
      programRes.value = res;
    });
  };
  // 获取program unChecked列表数量 用于Todo
  // 获取program unChecked列表数量 用于Todo
  const TodoListRes = ref();
  const ToDoListLen = computed(() => {
    let arr = [];
    if (TodoListRes.value) {
      arr = TodoListRes.value?.list?.filter((item: any) => {
        return unChecked.indexOf(item.status) !== -1;
      });
    }
    return arr.length;
  });
  const getCurProgramStatus = (res: any) => {
    if (res) {
      for (let i = 0; i < res.length; i++) {
        if (res[i].programId === curProgram.value.programId) {
          return res[i].status;
        }
      }
    }
  };
  const getTodoListLength = () => {
    getProgramFlList(payoutState.id).then((res: any) => {
      TodoListRes.value = res;
      const status = getCurProgramStatus(res.list);
      if (status) {
        curProgram.value.status = status;
      }
    });
  };
  // 列表数据
  const dataRes = ref();
  const data = computed(() => {
    return dataRes.value?.spotCheckVinRespVOList
      ? dataRes.value?.spotCheckVinRespVOList
      : [];
  });
  const isConfirmedSipo = computed(() => {
    if (data.value.length === 0) {
      return true;
    }
    return data.value.every((item: any) => {
      return item.isConfirmedSipo === "1";
    });
  });
  const isControllingChecked = computed(() => {
    if (data.value.length === 0) {
      return false;
    }
    return data.value.every((item: any) => {
      return item.checkStatus === "Controlling Reviewed";
    });
  });
  const summary = computed(() => {
    const obj = {};
    if (dataRes.value) {
      for (const key in dataRes.value) {
        if (key !== "spotCheckVinRespVOList") {
          obj[key] = dataRes.value[key];
        }
      }
    }
    return obj;
  });
  const getTableData = () => {
    const _query: any = { ...toRaw(query) };
    _query.rejectHistory = _query.rejectHistory ? "1" : "-1";
    _query.offerType = _query.siOffer
    fetchControllingcheckedListFl(_query).then((res) => {
      dataRes.value = res;
    })
  };
  // 分页
  const pagination = reactive({
    pageNumber: 1,
    pageSize: 10,
  });
  const total = computed(() => {
    return data.value.length;
  });
  const currentData = computed(() => {
    return data.value.slice(
      (pagination.pageNumber - 1) * pagination.pageSize,
      pagination.pageNumber * pagination.pageSize
    );
  });
  // 复选框
  const tableSelection = reactive<{
    selectedRowKeys: Key[];
    onSelectAll: Function;
    onChange: Function;
  }>({
    onChange: (selectedRowKeys: string[]) => {
      tableSelection.selectedRowKeys = selectedRowKeys;
    },
    onSelectAll: (selected: boolean) => {
      if (selected) {
        tableSelection.selectedRowKeys = data.value.map((item: any) => item.id);
      } else {
        tableSelection.selectedRowKeys = [];
      }
    },
    selectedRowKeys: [],
  });
  return {
    data,
    dataRes,
    currentData,
    column,
    query,
    summary,
    pagination,
    total,
    getTableData,
    tableSelection,
    getProgramList,
    unCheckedProgramList,
    ToDoListLen,
    getTodoListLength,
    curProgram,
    programIndex,
    isInProgram,
    isConfirmedSipo,
    isControllingChecked,
    statusList,
  };
};
export default useDealerVinView;
