
import { computed, defineComponent, ref, watch, reactive, toRefs } from "vue";
import useModel from "@/hooks/useModel";
import CollapseView from "./collapseView.vue";
import {
  fetchVinDetailFl,
  fetchVinDocDownload,
} from "@/API/checking/spotChecking";
import {
  documentReview,
  getDetailDocumentsApi,
  getDocumentTable,
  pdfViewApi,
  getDetailOperationRecordApi,
  getDetailRetailApi,
  getRVFile,
} from "@/API/checking/SICCOCheckingDetail";
import downloadFile from "@/utils/payment/downloadFile";
import { b64toBlob } from "@/utils/claim/b64toBlob";
export default defineComponent({
  name: "App",
  props: {
    title: {
      type: String,
      default: "Title",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    time: {
      type: String,
    },
    id: {
      type: String,
    },
    code: {
       type: String,
       default: ''
    }
  },
  setup(props) {
    const _visible = useModel(props, "visible");
    const _title = computed(() => {
      return `VIN：${props.title}`;
    });
    const column = ref([
      { title: "User", dataIndex: "user" },
      { title: "Activity", dataIndex: "activity" },
      { title: "Date", dataIndex: "date" },
      { title: "Comments", dataIndex: "comments" },
    ]);

    const vinDetail = reactive<any>({
      details: {},
      retailInvoice: {},
      supportDoc: {},
      docIds: [],
      operationList: []
    });
    const getSuffixAndValue = (str: string) => {
      const arr = [];
      if (str && str.length > 0) {
        const _str = str.split("/")[1];
        if (_str.endsWith("jpg") || _str.endsWith("JPG")) {
          arr[1] = "jpg";
        } else {
          arr[1] = "pdf";
        }
        arr[0] = str.split("/")[0];
      }
      return arr;
    };
    //Document data数据
    const documentSourceData = ref<any[]>([]);
    //Document data 表头
    const documentColumns = ref<any[]>([]);
    //Document slot
    const documentSolt = ref<any[]>([]);
    //处理Document返回的数据，并赋值
    const setDocement = (data: any) => {
      if (data === null || data.length === 0) {
        return;
      }
      const columnsArray = []; //表头
      const dataSourceColumnsArray = []; //table数据
      const soltArray = []; //插槽名称
      let tableWidth = 0;
      //生成表头,和数据对象的key
      for (let i = 0; i < data[0].length; i++) {
        if (i === 0) {
          columnsArray.push({ title: "", dataIndex: "name", key: "name" });
        } else {
          columnsArray.push({
            title: data[0][i],
            dataIndex: "rep" + i,
            key: "rep" + i,
            slots: { customRender: "rep" + i },
          });
          soltArray.push("rep" + i);
          tableWidth += 300;
        }
      }
      //生成数据
      for (let i = 1; i < data.length; i++) {
        const onerow = data[i]; //要处理的每一行数据

        const rowData = {}; //最终生成的行对象
        rowData["name"] = onerow[0];
        for (let j = 1; j < onerow.length; j++) {
          if (onerow[j] !== "") {
            rowData[columnsArray[j].dataIndex] = (onerow[
              j
            ] as any).supportingDocReName;
            rowData[columnsArray[j].dataIndex + "supportingDocId"] = (onerow[
              j
            ] as any).supportingDocId;
            rowData[columnsArray[j].dataIndex + "supportDocType"] = (onerow[
              j
            ] as any).supportDocType;
          } else {
            rowData[columnsArray[j].dataIndex] = "";
          }
        }
        dataSourceColumnsArray.push(rowData);
      }
      documentColumns.value = columnsArray;
      documentSourceData.value = dataSourceColumnsArray;
      documentSolt.value = soltArray;
    };

    const getVinDetailData = () => {
      if (props.id) {
        fetchVinDetailFl(props.id, props.code).then((res: any) => {
          if (res) {
            vinDetail.details = res.details ? res.details : {};
            vinDetail.retailInvoice = res.retailInvoice
              ? res.retailInvoice
              : {};
            vinDetail.supportDoc = res.supportDoc ? res.supportDoc : {};
            vinDetail.docIds = res.downloadDocumentIdList
              ? res.downloadDocumentIdList
              : [];
            vinDetail.operationList = res.operationList ? res.operationList : [];
            setDocement(vinDetail.supportDoc);

            const retailInvoice = vinDetail.retailInvoice;

            const invoiceArr = getSuffixAndValue(retailInvoice.invoice);
            retailInvoice.invoiceType = invoiceArr[1];
            if (invoiceArr.length) {
              retailInvoice.invoiceStr =
                "IN_" + retailInvoice.vin + "." + retailInvoice.invoiceType;
            } else {
              retailInvoice.invoiceStr = "";
            }

            const vehicleLicenseArr = getSuffixAndValue(
              retailInvoice.vehicleLicense
            );
            retailInvoice.vehicleLicenseType = vehicleLicenseArr[1];
            if (vehicleLicenseArr.length) {
              retailInvoice.vehicleLicenseStr =
                "RE_" +
                retailInvoice.vin +
                "." +
                retailInvoice.vehicleLicenseType;
            } else {
              retailInvoice.vehicleLicenseStr = "";
            }
          }
        });
      }
    };

    const handleDocDownLoad = () => {
      fetchVinDocDownload(vinDetail.docIds).then((res) => {
        window.location.href = res.downloadUrl;
      });
    };

    const RVVisable = ref(false);
    const RVFile = ref("");
    const RVType = ref("");
    //显示发票
    const showIn = () => {
      const retailInvoice = vinDetail.retailInvoice;
      if (retailInvoice.invoice === "" || retailInvoice.invoice.length <= 1) {
        return;
      }
      RVType.value = "";
      RVFile.value = "";
      if (retailInvoice.invoiceType === "pdf") {
        const path = retailInvoice.invoice + "/PDF";
        pdfViewApi({ licensePath: path, fileName: "xx" }).then((res) => {
          RVFile.value = URL.createObjectURL(
            b64toBlob(res.base64, "application/pdf")
          );
          RVType.value = "PDF";
          RVVisable.value = true;
        });
      } else {
        getRVFile({
          id: retailInvoice.invoice.split("/")[0],
          // id: "ad890277-0022-43d9-b0c5-333d5f3be0e3",
          type: "jpg",
          fileName: retailInvoice.vin + ".jpg",
        }).then((res) => {
          RVFile.value = res.downloadUrl;
          RVType.value = res.docType;
          RVVisable.value = true;
        });
      }
    };
    //显示行驶证
    const showRE = () => {
      const retailInvoice = vinDetail.retailInvoice;
      if (retailInvoice.invoice === "" || retailInvoice.invoice.length <= 1) {
        return;
      }
      RVType.value = "";
      RVFile.value = "";
      if (retailInvoice.vehicleLicense === "pdf") {
        const path = retailInvoice.vehicleLicense + "/PDF";
        pdfViewApi({ licensePath: path, fileName: "xx" }).then((res) => {
          RVFile.value = URL.createObjectURL(
            b64toBlob(res.base64, "application/pdf")
          );
          RVType.value = "PDF";
          RVVisable.value = true;
        });
      } else {
        getRVFile({
          id: retailInvoice.vehicleLicense,
          type: retailInvoice.vehicleLicenseType,
          fileName: retailInvoice.vin,
        }).then((res) => {
          RVFile.value = res.downloadUrl;
          RVType.value = res.docType;
          RVVisable.value = true;
        });
      }
    };
    watch(
      () => props.time,
      () => {
        getVinDetailData();
      },
      {
        immediate: true,
      }
    );

    return {
      _visible,
      _title,
      column,
      bodyStyle: {
        height: "calc(100% - 80px)",
        overflow: "auto",
      },
      ...toRefs(vinDetail),
      handleDocDownLoad,
      documentSourceData,
      documentColumns,
      documentSolt,
      showIn,
      RVVisable,
      RVFile,
      RVType,
      showRE,
    };
  },
  components: {
    CollapseView,
  },
});
