
import { defineComponent, computed } from "vue";
import useModel from "@/hooks/useModel";
export default defineComponent({
  name: "App",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const _visible = useModel(props, "visible");
    const handleCancel = () => {
      _visible.value = false;
    };
    const handleConfirm = () => {
      context.emit('on-confirm')
       _visible.value = false;
    }
    const handleReturn = () => {
      context.emit('on-return')
       _visible.value = false;
    }
    return {
      _visible,
      handleCancel,
      handleConfirm,
      handleReturn
    };
  },
});
