
import { defineComponent, reactive, ref, toRefs, toRaw } from 'vue';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import TableWrapper from '@/views/Payment/components/TableWrapper.vue';
import searchTitle from '../components/searchTitle.vue';
import SamplingModal from '../components/samplingModalFl.vue';
import VinDrawer from '../components/vinDrawerFl.vue';

import useControllingChecked from '@/hooks/checking/spotChecking/checkingConfirm/useFlVinView';
import { spotCheckSampledOptions, controllingSampledOptions, checkStatusOptions } from '../components/selectOptions';
import {
  fetchVinFlBatch,
  fetchVinFlToSipo,
  fetchIsToSipo,
  fetchVinFlBatchConfirm,
  fetchVinFlBatchReturn,
  financialVerifyReturnByProgramId,
  financialVerifyConfirmByProgramId,
  financialAddComments2,
  verifyFinancialCheckBeforeConfirm,
  financialConfirm2FLTip
} from '@/API/checking/spotChecking';
import downloadFile from '@/utils/payment/downloadFile';
import { toThousandsAndTwoDecimal } from '@/utils/payment/numberFormat';
import ConfirmToFlModal from '../components/confirmToFlModal.vue';
import ReturnConfirmModal from '../components/returnModalConfirm.vue';
export default defineComponent({
  name: 'App',
  props: {},
  setup() {
    const isOpen = ref<boolean>(false);
    const { state } = useStore();
    const payoutState = state.spotChecking.spotCheckConfirmPackageFl;
    const {
      column,
      data,
      dataRes,
      currentData,
      tableSelection,
      query,
      summary,
      getTableData,
      total,
      pagination,
      curProgram,
      getProgramList,
      unCheckedProgramList,
      ToDoListLen,
      getTodoListLength,
      programIndex,
      isInProgram,
      isConfirmedSipo,
      isControllingChecked,
      statusList
    } = useControllingChecked();
    const init = () => {
      getProgramList();
      getTodoListLength();
      getTableData();
    };
    init();
    const search = () => {
      tableSelection.selectedRowKeys = [];
      getTableData();
    };
    const reset = () => {
      query.checkStatus = '-1';
      query.controllingSampled = '-1';
      query.model = '';
      query.rejectHistory = false;
      query.spotCheckSampled = '1';
      query.vin = '';
      query.typeClass = '';
      search();
    };
    const initPageData = () => {
      getTodoListLength();
      if (programIndex.value !== undefined && unCheckedProgramList.value.length > 0) {
        curProgram.value = unCheckedProgramList.value[programIndex.value];
      }
      query.programCode = curProgram.value?.programCode;
      query.siOffer = curProgram.value?.siOffer;
      search();
    };
    const isNoData = () => {
      if (tableSelection.selectedRowKeys.length === 0) {
        message.warning('Please select the data first');
        return true;
      }
      return false;
    };
    //#region action
    const samplingModalVisible = ref(false);
    const handleSamling = () => {
      samplingModalVisible.value = true;
    };
    const handleSamplingChange = (val: any) => {
      message.success('Sampling Successfully');
      pagination.pageNumber = 1;
      pagination.pageSize = 10;
      search();
    };
    const vinDrawerVisible = ref(false);
    const vinDrawTitle = ref();
    const time = ref(new Date().toString());
    const curVinId = ref();
    const handleVinDrawerShow = (record: any) => {
      curVinId.value = record.id;
      time.value = new Date().toString();
      vinDrawerVisible.value = true;
      vinDrawTitle.value = record.vin;
    };
    const handleNext = () => {
      if (!isInProgram.value) {
        programIndex.value = 0;
        isInProgram.value = true;
      } else if (programIndex.value === unCheckedProgramList.value.length - 1) {
        programIndex.value = 0;
      } else {
        programIndex.value++;
      }
      if (programIndex.value !== undefined && unCheckedProgramList.value.length > 0) {
        curProgram.value = unCheckedProgramList.value[programIndex.value];
      }
      query.programCode = curProgram.value?.programCode;
      query.siOffer = curProgram.value?.offerType;
      search();
    };
    const commentModalVisible = ref(false);
    const comment = ref('');

    let resolveAddComment: ((value: unknown) => void) | null = null;
    let rejectAddComment: ((reson?: any) => void) | null = null;

    const handleAddComment = async () => {
      await financialAddComments2(comment.value, [query.packageCodeId]);
      if (comment.value.length === 0) {
        message.error('Reason is required');
        return;
      }
      resolveAddComment?.(null);
    };

    const handleCancelComment = () => {
      rejectAddComment?.();
    };

    const handleConfirm = async () => {
      if (isNoData()) {
        return;
      }
      const result = await financialVerifyConfirmByProgramId(curProgram.value.programId);
      if (!result.data) {
        // message.warning(result.message);
        return;
      }
      const checkBeforeConfirm = await verifyFinancialCheckBeforeConfirm([query.payoutCodeId]);
      await new Promise((resolve, reject) => {
        if (checkBeforeConfirm) {
          resolveAddComment = resolve;
          rejectAddComment = reject;
          comment.value = '';
          commentModalVisible.value = true;
        } else {
          resolve(null);
        }
      }).finally(() => {
        commentModalVisible.value = false;
      });
      fetchVinFlBatch(tableSelection.selectedRowKeys as string[], payoutState.id, '1', '1').then(() => {
        message.success('Confirm Successfully');
        initPageData();
      });
    };

    const handleRowConfirm = async (row: any, disabled: boolean) => {
      if (disabled) {
        return false;
      }
      const result = await financialVerifyConfirmByProgramId(curProgram.value.programId);
      if (!result.data) {
        // message.warning(result.message);
        return;
      }
      const checkBeforeConfirm = await verifyFinancialCheckBeforeConfirm([query.payoutCodeId]);
      await new Promise((resolve, reject) => {
        if (checkBeforeConfirm) {
          resolveAddComment = resolve;
          rejectAddComment = reject;
          comment.value = '';
          commentModalVisible.value = true;
        } else {
          resolve(null);
        }
      }).finally(() => {
        commentModalVisible.value = false;
      });
      fetchVinFlBatch([row.id], payoutState.id, '1', '2').then(() => {
        message.success('Confirm Successfully');
        initPageData();
      });
    };

    const returnModalVisible = ref(false);
    const returnCommentModalVisible = ref(false);
    const handleReturn = async (disabled = false) => {
      if (isConfirmedSipo.value || curProgram.value.status === 'Controlling Reviewed') {
        return false;
      }
      const result = await financialVerifyReturnByProgramId(curProgram.value.programId);
      if (!result.data) {
        // message.warning(result.message);
      } else {
        returnModalVisible.value = true;
      }
    };

    const handleReturnConfirm = async (comments: string) => {
      if (comments.length === 0) {
        message.error('Reason is required');
        return;
      }
      await financialAddComments2(comments, [query.payoutCodeId]);
      const ids = data.value.map((item: any) => item.id);
      await fetchVinFlBatch(ids, payoutState.id, '2', '1');
      message.success('Return Successfully');
      initPageData();
      returnCommentModalVisible.value = false;
    };
    // const handleConfirmToSipo = () => {
    //   const programCode = query.programCode;
    //   const type = query.siOffer;
    //   const msg = `Program ${programCode} & Offer Type ${type} can not be confirmed without controlling confirmed`;
    //   fetchVinFlToSipo(programCode, type, payoutState.id).then((res) => {
    //     if (res.data === 1) {
    //         message.success("Confirm Successfully");
    //         initPageData();
    //       } else if (res.data === 0) {
    //         message.error(msg);
    //         initPageData();
    //       }
    //   });
    // };
    const flModalVisible = ref(false);
    const flModalMessage = ref('');
    const formatOfferType = (str: string) => {
      return str
        ?.split('')
        .map((_str) => {
          if (_str === '&') {
            return '%26';
          } else {
            return _str;
          }
        })
        .join('');
    };
    const handleConfirmToSipo = async () => {
      const ids = data.value.map((item: any) => item.id);
      const msg = `Program ${query.programCode} & Offer Type ${query.siOffer} can not be confirmed without controlling confirmed`;
      const result = await fetchIsToSipo(query.programCode, query.siOffer, query.payoutCodeId, ids);
      if (!result) {
        message.error(msg);
      } else {
        flModalMessage.value = decodeURIComponent(
          await financialConfirm2FLTip(query.siOffer, query.programCode, query.payoutCodeId)
        );
        flModalVisible.value = true;
      }
    };
    const handleConfirmToFl = () => {
      const offerType = formatOfferType(query.siOffer);
      fetchVinFlBatchConfirm(offerType, query.programCode, query.payoutCodeId).then((res: any) => {
        message.success('Confirm Successfully');
        initPageData();
      });
    };
    const handleReturnToFl = () => {
      const offerType = formatOfferType(query.siOffer);
      fetchVinFlBatchReturn(offerType, query.programCode, query.payoutCodeId).then((res: any) => {
        message.success('Confirm Successfully');
        initPageData();
      });
    };
    const handleExport = () => {
      const _query: any = { ...toRaw(query) };
      _query.rejectHistory = _query.rejectHistory ? '1' : '0';
      _query.offerType = _query.siOffer;
      if (_query.checkStatus.length === 0) {
        _query.checkStatus = statusList.value.map((item: any) => item.name);
      }
      const params = {
        url: `/claimapi/SpotChecking/toExcelFl`,
        method: 'post',
        params: _query
      };
      downloadFile(params).catch((res) => {
        // if (res.message) {
        //   message.warning(res.message)
        // } else {
        //   message.warning(res)
        // }
      });
    };
    //#endregion
    return {
      comment,
      commentModalVisible,
      handleAddComment,
      handleCancelComment,
      column,
      data,
      currentData,
      tableSelection,
      query,
      ...toRefs(query),
      summary,
      search,
      reset,
      isOpen,
      total,
      ...toRefs(pagination),
      isPagination: true,
      spotCheckSampledOptions,
      controllingSampledOptions,
      // checkStatusOptions,
      statusList,
      handleSamling,
      handleSamplingChange,
      samplingModalVisible,
      handleVinDrawerShow,
      vinDrawerVisible,
      vinDrawTitle,
      unCheckedProgramList,
      ToDoListLen,
      handleNext,
      curProgram,
      programIndex,
      isInProgram,
      handleConfirm,
      handleReturn,
      returnModalVisible,
      returnCommentModalVisible,
      handleReturnConfirm,
      handleConfirmToSipo,
      handleRowConfirm,
      isConfirmedSipo,
      isControllingChecked,
      handleExport,
      toThousandsAndTwoDecimal,
      flModalVisible,
      flModalMessage,
      handleConfirmToFl,
      handleReturnToFl,
      curVinId,
      time
    };
  },
  components: {
    searchTitle,
    TableWrapper,
    SamplingModal,
    VinDrawer,
    ConfirmToFlModal,
    ReturnConfirmModal
  }
});
